.overlay {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;

    &__logo {
        position: absolute;
        top: 25px;
        left: 25px;
        font-size: 13px;

        @media (min-width: 574px) {
            top: 71px;
            left: 25px;
        }

        @media (min-width: 768px) {
            top: 71px;
            left: 90px;
        }
    }

    &__link {
        position: absolute;
        top: 40px;
        right: 25px;

        @media (min-width: 574px) {
            top: 71px;
            right: 25px;
        }

        @media (min-width: 768px) {
            top: 71px;
            right: 90px;
        }

        a {
            --line: #29f29f;
            text-decoration: none;
            color: #a0a0a0;
            position: relative;

            &:hover {
                --background-size: 0%;
                --background-delay: 0s;
                --stroke-dashoffset: 26;
                --stroke-duration: 0.3s;
                --stroke-easing: cubic-bezier(0.3, 1.5, 0.5, 1);
                --stroke-delay: 0.195s;
            }

            span {
                background-image: linear-gradient(0deg, var(--line) 0%, var(--line) 100%);
                background-position: 100% 100%;
                background-repeat: no-repeat;
                background-size: var(--background-size, 120%) 1px;
                transition: background-size 0.2s linear var(--background-delay, 0.15s);
                font-size: 16px;
                line-height: 20px;
                transform: translateZ(0);
            }
            svg {
                vertical-align: top;
                display: inline;
                line-height: 1;
                width: 13px;
                height: 20px;
                position: relative;
                left: -2px;
                fill: none;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 1px;
                stroke: var(--line);
                stroke-dasharray: 7.95 30;
                stroke-dashoffset: var(--stroke-dashoffset, 46);
                transition: stroke-dashoffset var(--stroke-duration, 0.15s) var(--stroke-easing, linear) var(--stroke-delay, 0s);
            }
        }
    }

    p {
        position: absolute;
        bottom: 25px;
        left: 25px;
        font-size: 13px;
        display: inline-table;

        @media (min-width: 574px) {
            bottom: 71px;
            left: 25px;
        }

        @media (min-width: 768px) {
            bottom: 71px;
            left: 90px;
        }
    }
}
