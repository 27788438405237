.ring {
    position: fixed;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    border: 1px solid #fff;
    opacity: 0.88;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    will-change: width, height;
    pointer-events: none;
    z-index: 999;
    transition: height 100ms ease-out, width 100ms ease-out;

    &.hovered-dot {
        width: 50px;
        height: 50px;
        border-width: 1px;
        border-color: #fff;
        opacity: 0.88;
        z-index: 999;
        transition: height 100ms ease-out, width 100ms ease-out;
    }

    &.hovered-open {
        width: 50px;
        height: 50px;
        border-width: 1px;
        border-color: #000;
        opacity: 0.88;
        z-index: 999;
        transition: height 100ms ease-out, width 100ms ease-out, color 100ms ease-out;
    }
}

.dot {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 4px;
    background-color: #fff;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    z-index: 999;
    pointer-events: none;

    &.hovered-open {
        display: none;
        z-index: 999;
    }
}

.open {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    pointer-events: none;
    opacity: 0;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    transition: opacity 100ms ease-in-out, color 100ms ease-out;

    &.hovered-open {
        opacity: 0.88;
        z-index: 999;
        color: #000;
    }
}
