// Components
@import "./loader";
@import "./overlay";
@import "./cursor";

// Base
@import url("https://rsms.me/inter/inter.css");

* {
    box-sizing: border-box;
}

html {
    font-size: 16px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

html,
body,
#root,
.App {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    overscroll-behavior: none;
    cursor: none;
    background: #151515;
    color: #a0a0a0;
    font-family: "Inter var", sans-serif;

    #root {
        .App {
            .inner {
                height: 100%;

                .playground {
                    height: 100%;

                    .container {
                        height: 100%;
                        width: 100%;
                        position: relative;

                        h1 {
                            font-size: 30vw;
                            font-weight: 400;
                            letter-spacing: -0.05em;
                            line-height: 0.7em;
                            margin: 0;
                            padding: 0;
                        }

                        a {
                            cursor: none;
                            pointer-events: all;
                            color: #a0a0a0;
                            text-decoration: none;
                        }

                        div:first-child {
                            div {
                                div {
                                    overflow: scroll !important;
                                    overflow-y: hidden !important;

                                    @media (min-width: 1025px) {
                                        overflow: hidden !important;
                                    }

                                    div {
                                        overflow: hidden !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .noise {
                overflow: hidden;
                z-index: 10000000;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
                opacity: 0.06;
            }
        }
    }
}
