.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;

    .loader-inner {
        width: 100%;
        .loading-text {
            text-align: center;

            svg {
                height: 170px;
                width: 120px;

                @media screen and (min-width: 1200px) {
                    height: 170px;
                    width: 200px;
                }
            }
        }
    }
}
